<template>
  <common>
    <template v-slot:headers>
      <div @click="back" class="back-img" />
      <div class="title">联系人</div>
      <div style="margin-left: auto; margin-right: 1.79rem"></div>
    </template>
    <div class="contacts">
      <div class="flex-w screen-search">
        <input class="search" placeholder="搜索" v-model="memberKeyword" />
      </div>
      <div class="full-h" style="overflow: scroll">
        <div class="contact-box">
          <div class="title">群组</div>
          <div class="flex-w contact-item" v-for="item in groupMap">
            <div class="head-draw head">{{ lastName(item.group_name) }}</div>
            <div class="flex-w name">{{ item.group_name }}</div>
          </div>
        </div>
        <div class="contact-box">
          <div class="title">团队</div>
          <div class="flex-w contact-item" v-for="item in teamMap">
            <div class="head-draw head">{{ lastName(item.nickname) }}</div>
            <div class="flex-w name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
  </common>
</template>
<script>
import { postChatwsTeam, postChatwsGroup } from '@/service/help.js';
export default {
  data() {
    return {
      memberKeyword: '',
      team: [],
      group: [],
    };
  },
  mounted() {
    this.switchFoot(false);
    this.getData();
  },
  methods: {
    back() {
      this.$router.push('/chat/communicate');
    },
    getData() {
      postChatwsTeam({ keyword: '' }).then(res => {
        this.team = res.data.data;
      });
      postChatwsGroup({ keyword: '' }).then(res => {
        this.group = res.data.data;
      });
    },
  },
  computed: {
    teamMap() {
      return this.team.filter(v => v.nickname.indexOf(this.memberKeyword) >= 0);
    },
    groupMap() {
      return this.group.filter(v => v.group_name.indexOf(this.memberKeyword) >= 0);
    },
  },
};
</script>
<style lang="less">
.contacts {
  width: 100%;
  height: 100%;
  border-radius: 1rem 1rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  .screen-search {
    flex: 0 0 3.75rem;
    background: #ffffff;
    border-bottom: 0.09rem solid #e8ecef;
    padding: 0.6rem 0;
    .search {
      margin-right: 1.02rem;
      margin-left: 1.37rem;
      // width: 26.37rem;
      width: 100%;
      height: 2.56rem;
      border-radius: 0.17rem;
      // border:1px solid red;
      background: rgb(247, 248, 250) url('~@/assets/image/icon-progress/icon_search@2x.png') no-repeat 11.09rem 0.68rem;
      background-size: 1.2rem 1.19rem;
      padding: 1rem;

      &::placeholder {
        opacity: 0.25;
        text-align: center;
        color: #c2c7d9;
        transform: translateX(0.6rem);
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }

        background-image: none;
      }
    }
  }

  .contact-box {
    margin-top: 0.68rem;
    width: 32rem;
    height: auto;
    background-color: #fff;
    padding: 0 1.02rem;
    .title {
      width: 32rem;
      height: 2.56rem;
      line-height: 2.56rem;
      font-size: 1.19rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8590b3;
    }
    .contact-item {
      width: 100%;
      height: 4.61rem;
      justify-content: flex-start;
      flex-wrap: nowrap;
      .head {
        margin-right: 1.02rem;
        width: 3.07rem;
        height: 3.07rem;
        font-size: 1.19rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 3.07rem;
        color: #ffffff;
      }
      .name {
        overflow: hidden;
        width: 26.88rem;
        height: 100%;
        justify-content: flex-start;
        border-bottom: 0.04rem solid #e8ecef;
      }
    }
  }
}
</style>
